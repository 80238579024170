<template>
  <form @submit.prevent="saveDetails">
    <modal-card
      :title="`${user.company ? 'Update' : 'Add'} company details`"
      :processing="processing"
    >
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field label="Company name *">
            <b-input
              v-model="form.name"
              required
              placeholder="Your Company Name"
            />
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="VAT Number *">
            <b-input
              v-model="form.vatNumber"
              required
              placeholder="GB 123456789"
            />
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Address *">
            <b-input
              v-model="form.address.line1"
              required
              placeholder="Address line 1"
            />
          </b-field>
          <b-field>
            <b-input
              v-model="form.address.line2"
              placeholder="Address line 2"
            />
          </b-field>
        </div>
        <div class="column is-8">
          <b-field label="City *">
            <b-input v-model="form.address.city" required placeholder="City" />
          </b-field>
        </div>
        <div class="column is-4">
          <b-field label="Postcode *">
            <b-input
              v-model="form.address.postcode"
              required
              placeholder="Postcode"
            />
          </b-field>
        </div>
        <div class="column is-12">
          <b-field label="Country *">
            <b-select
              v-model="form.address.country"
              :value="form.address.country.code"
              :disabled="processing"
              :placeholder="loading ? `Loading countries` : `Select a country`"
              required
              expanded
            >
              <option
                v-for="(country, index) in countries"
                :key="index"
                :value="country"
                >{{ country.name }}</option
              >
            </b-select>
          </b-field>
        </div>
      </div>
      <button
        slot="footer"
        :class="{ 'is-loading': processing }"
        :disabled="!validForm || processing"
        type="submit"
        class="button is-success"
      >
        Save
      </button>
    </modal-card>
  </form>
</template>

<script>
import countries from "@src/data/countries";
import { doc, serverTimestamp, updateDoc } from "@firebase/firestore";
export default {
  name: "CompanyDetailsModal",
  props: {
    userId: {
      type: String,
      required: true
    },
    default: {
      type: Object,
      required: false,
      default() {
        return {
          name: "",
          vatNumber: "",
          address: {
            line1: "",
            line2: "",
            postcode: "",
            country: countries.GB
          }
        };
      }
    }
  },
  data() {
    return {
      loading: false,
      processing: false,
      form: this.$_.cloneDeep(this.default)
    };
  },
  computed: {
    countries() {
      return this.$_.sortBy(countries, ["name"], ["asc"]);
    },
    user() {
      return this.$store.getters["user/user"](this.userId);
    },
    validForm() {
      if (this.$_.isEqual(this.form, this.default)) return false;
      if (this.$_.isEmpty(this.form.name.trim())) return false;
      if (this.$_.isEmpty(this.$_.get(this.form, "vatNumber", "").trim()))
        return false;
      if (this.$_.isEmpty(this.$_.get(this.form, "address.line1", "").trim()))
        return false;
      if (this.$_.isEmpty(this.$_.get(this.form, "address.city", "").trim()))
        return false;
      if (
        this.$_.isEmpty(this.$_.get(this.form, "address.postcode", "").trim())
      )
        return false;
      if (this.$_.isEmpty(this.$_.get(this.form, "address.country", "")))
        return false;
      return true;
    }
  },
  methods: {
    saveDetails() {
      this.processing = true;
      const userRef = doc(this.$firestore, `users`, this.userId);
      const payload = {
        company: this.form,
        dateUpdated: serverTimestamp()
      };
      updateDoc(userRef, payload)
        .then(() => {
          this.processing = false;
          this.$emit("close");
          this.$toast.open({
            message: `Company details ${
              this.user.company ? "updated" : "added"
            }`
          });
        })
        .catch(() => {
          this.processing = false;
          this.$toast.open({
            message: "Error saving company details",
            type: "is-danger"
          });
        });
    }
  }
};
</script>
